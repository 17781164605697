<template>
  <client-only>
    <div v-show="visible" id="loader" :class="customClass" sticky-container>
      <div class="l-cell">
        <p class="fwb">
          {{ text }}
        </p>
      </div>
    </div>
  </client-only>
</template>
<script lang="ts">
export default defineComponent({
  props: {
    visible: Boolean,
    text: {
      type: String,
      default: "загрузка...",
    },
    customClass: String,
  },
  setup() {
    onMounted(() => {});
  },
});
</script>

<style scoped lang="scss">
#loader {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  left: -20px;
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  z-index: 11;

  &.full-hd {
    width: 100vw;
    position: fixed;
  }

  .l-cell {
    //margin: 10% auto;
    position: sticky;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    &:deep(svg) {
      will-change: transform;

      path:not(:nth-child(3)) {
        transform-origin: center;
        animation-name: path-animation;
        animation-iteration-count: infinite;
        // animation-direction: alternate;
        animation-duration: 1s;
        transform: scale(0);
      }

      path:nth-child(1) {
        animation-delay: 0.5s;
      }
    }
  }

  p {
    margin-bottom: 48px;
  }
}

@keyframes path-animation {
  from {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  49% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
